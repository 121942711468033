import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Axios from "axios";
import * as Yup from "yup";

const request = {
  method: "POST",
  url: "/api/unsubscribe",
  headers: {
    Pragma: "no-cache",
    "Content-Type": "application/json",
  },
};
// @ts-ignore
function getUrlParams(name, url) {
  // eslint-disable-next-line
  const adjustedName = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + adjustedName + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results || !results[2]) return null;
  return results[2];
}

const validation = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Please enter a valid email address.")
    .max(100, "Must be 100 characters or fewer.")
    .required("Please enter a valid email address."),
});

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="15"
    className="hallmark-logo"
    fill="none"
    viewBox="0 0 19 15"
  >
    <path
      fill="#0E5432"
      d="M10.05 1.245a.67.67 0 00-.657-.66.67.67 0 00-.656.66c0 .355.303.66.656.66a.67.67 0 00.656-.66zm4.543 2.942a.67.67 0 00-.656-.66.67.67 0 00-.657.66c0 .355.303.66.657.66a.67.67 0 00.656-.66zm-9.036 0a.67.67 0 00-.657-.66.67.67 0 00-.656.66c0 .355.303.66.656.66a.67.67 0 00.657-.66zm12.52.254a.67.67 0 00-.657-.66.67.67 0 00-.656.66c0 .355.303.66.656.66a.67.67 0 00.656-.66zm-16.054 0a.67.67 0 00-.657-.66.67.67 0 00-.656.66c0 .355.303.66.656.66a.67.67 0 00.657-.66zm14.74 1.522c.152-.355-.15-.457-.302-.254-.455.812-1.11 2.03-1.818 2.74-.353.355-1.01.202-1.06-.356-.151-.964 0-1.674.05-2.384 0-.304-.252-.355-.353-.101-.303.66-.505 1.47-1.06 2.13-.505.558-1.211.355-1.565-.101-.757-1.015-.959-3.653-1.06-4.87-.05-.254-.353-.254-.353 0-.101 1.217-.303 3.855-1.06 4.87-.354.507-1.06.66-1.565.101-.606-.66-.808-1.471-1.06-2.13-.101-.254-.354-.203-.354.101.05.71.202 1.471.05 2.384-.1.558-.706.761-1.11.355-.757-.76-1.363-1.978-1.817-2.739-.152-.254-.455-.101-.303.254 1.01 2.232 2.17 5.53 2.271 5.682.101.101.152.152.202.101 1.111-.609 3.08-.862 4.897-.862 1.817 0 3.786.253 4.897.862.05.05.1 0 .202-.101.1-.102 1.262-3.45 2.272-5.682zm-7.37 5.783c-1.918 0-4.745.355-4.745 1.268 0 .964 2.827 1.32 4.745 1.32 1.918 0 4.745-.356 4.745-1.32 0-.913-2.826-1.268-4.745-1.268zm0 2.13c-1.817 0-4.14-.304-4.14-.862 0-.507 2.323-.811 4.14-.811 1.767 0 4.14.304 4.14.811 0 .558-2.373.863-4.14.863z"
    ></path>
  </svg>
);

function App() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const email = getUrlParams("email", window.location.search);
  const showInput = !email;

  const headerCopy = showInput
    ? "If you would like to unsubscribe from Hallmark Channel's Very Merry Giveaway Sweepstakes emails, please enter your email below:"
    : "If you would like to unsubscribe from Hallmark Channel's Very Merry Giveaway Sweepstakes emails, please click the unsubscribe link below:";

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    // @ts-ignore
    Axios({ ...request, data: { EmailAddress: values.emailAddress } })
      .then(() => {
        setShowSuccess(true);
      })
      .finally(() => setIsSubmitting(false));

    // need to hook up apoi
  };

  if (showSuccess) {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <Icon />
          <p>Successfully unsubscribed</p>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <Icon />
        <p>{headerCopy}</p>
        <Formik
          initialValues={{
            // @ts-ignore
            emailAddress: showInput ? "" : decodeURIComponent(email),
          }}
          validationSchema={validation}
          onSubmit={onSubmit}
        >
          {() => (
            <Form id="UnsubscribeForm" noValidate className="content-form">
              <div className="input-group">
                {showInput && (
                  <Field
                    name="emailAddress"
                    id="emailAddress"
                    type="email"
                    placeholder="Email Address*"
                    maxLength={100}
                    aria-label="email address"
                    className="form-control"
                  />
                )}

                <div className="input-group-append">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Unsubscribe
                  </Button>
                </div>
              </div>
              <div>
                <ErrorMessage
                  render={(msg) => <div className="content-error">{msg}</div>}
                  name="emailAddress"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}

export default App;
